import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from './axiosConfig';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [press, setPress] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPress(true)
    setError('');
    setMessage('');

    try {
      const response = await axios.post('/api/forgot-password', { email });
      setMessage(response.data.message);
      setPress(false)
    } catch (error) {
      setError(error.response.data.error);
      setPress(false)
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Forgot Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="absolute">
            {error && <p className="mb-1 text-red-500 text-sm">{error}</p>}
            {message && <p className="mb-1 text-green-500 text-sm">{message}</p>}
          </div >
          <div className="flex items-center justify-between mt-10">
            <button
              type="submit"
              disabled = {press ? true: false}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {press ? "Loading..." : "Send Reset Email"} 
            </button>
          </div>
          <div className="mt-4">
            <Link to="/login" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
