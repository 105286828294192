import React from 'react';
import { motion } from 'framer-motion';

const About = () => {


  return (
    
    <motion.section 
      id="about" 
      className="py-20 bg-gray-50"
      initial={{ opacity: 0 }}
    //   animate={{ opacity: 1 }}
      transition={{ duration: 5 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800">About Me</h2>
        <motion.p 
          className="text-gray-600 mt-4 px-20 text-wrap text-wrap"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
          
        >
          I'm a passionate AI developer, single-handedly building intelligent applications that solve real-world problems. 
          With a deep understanding of machine learning, I'm dedicated to pushing the boundaries of what's possible for AI.
        </motion.p>
      </div>
    </motion.section>
  );
};

export default About;
