import React, { useContext } from 'react';
import { AuthContext } from '../components/AuthContext';

const Dashboard = () => {
  const { logout } = useContext(AuthContext); // Check this line


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await logout();
      console.log(response);
    } catch (err) {
      console.log('Logout failed.', err);
    }

  };

  return (
    <div>
      <h1>Dashboard</h1>
      <button onClick={handleSubmit}
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Logout
      </button>
    </div>
  )
};

export default Dashboard;
