import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from './axiosConfig';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [press, setPress] = useState(false);

  const handleSubmit = async (e) => {
    setPress(true)
    e.preventDefault();
    setError('');
    setMessage('');

    const validateEmail = (email) => {
      // Email validation regex pattern
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setPress(false)
      return re.test(email);
    };

    // Basic validation
    if (!email || !password || !confirmPassword) {
      setError('All fields are required.');
      setPress(false)
      return;
    }

    if (!validateEmail(email)) {
      setError('Invalid email address.');
      setPress(false)
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setPress(false)
      return;
    }

    if (password.length < 6) {
      setError('Password should be at least 6 characters.');
      setPress(false)
      return;
    }

    try {
      const response = await axios.post('/api/signup', {
        email,
        password,
      });
      setMessage(response.data.message);
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPress(false)
    } catch (error) {
      setError(error.response.data.error);
      setPress(false)
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="absolute">
            {error && <p className="text-red-500 text-sm">{error}</p>}
            {message && <p className="text-green-500 text-sm">{message}</p>}
          </div>
          <div className="flex items-center justify-between mt-10">
            <button
              type="submit"
              disabled = {press ? true: false}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
                disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            >
              {press ? "Loading..." : "Sign Up"}
            </button>
          </div>
          <div className="mt-4">
            <Link to="/login" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
              Already have an account? Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
