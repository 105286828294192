import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import './App.css'
import Home from './pages';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Chatbotmemory from './pages/chatbotmemory';
import Chatbotrag from './pages/chatbotrag';


function App() {
    return (
        <Router>
            <AuthProvider>
            <div className="p-4">
                <Routes>
                    <Route exact path="/" element={<Home/>} />    
                    <Route path="/login" element={<Login/>} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword/>} />
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>
                    <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>}/>
                    <Route path="/chatbot/memory" element={<ProtectedRoute><Chatbotmemory/></ProtectedRoute>} />
                    <Route path="/chatbot/rag" element={<ProtectedRoute><Chatbotrag/></ProtectedRoute>} />
                </Routes>
            </div>
            </AuthProvider>       
        </Router>
    );
}

export default App;
