import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from './axiosConfig';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [press, setPress] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPress(true)
    setError('');
    setMessage('');
    

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setPress(false)
      return;
    }

    try {
      console.log('Token:', token, newPassword)
      const response = await axios.post('/api/reset-password', { token: token, new_password: newPassword });
      setMessage(response.data.message);
      navigate('/login');
      setPress(false)
    } catch (error) {
      setError(error.response.data.error);
      setPress(false)
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Reset Password</h2>
        <div className="absolute top-0 w-full text-center">
          {error && <p className="mb-4 text-red-500">{error}</p>}
          {message && <p className="mb-4 text-green-500">{message}</p>}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              disabled = {press ? true: false}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {press ? "Loading..." : "Reset Password"} 
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
