import React, { createContext, useState, useEffect } from 'react';
import axios from './axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  


  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/dashboard', {
          // headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setAuth(response.data.logged_in_as);
      } catch (error) {
        setAuth(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  console.log(auth)

  const login = async (email, password) => {
    try {
      const responseLogin = await axios.post('/api/login', { email, password });
      if (responseLogin.data.error) {
        return responseLogin.data.error;
      }
      const response = await axios.get('/api/dashboard');
      setAuth(response.data.logged_in_as);
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (error) {
      // console.error('Login failed', error.response.data);
      return('Login failed.')
    }
  };

  const logout = async () => {
    await axios.post('/api/logout');
    setAuth(null);
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ auth, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
