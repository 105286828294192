import React, { useState, useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import axios from '../components/axiosConfig';

const Profile = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [email, setEmail] = useState(auth?.email || '');
  const [newEmail, setNewEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  if (!auth) {
    return <div>Loading...</div>;
  }

  console.log(auth.logged_in_as)

  const handleEmailChange = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const response = await axios.put('/api/profile/email', { email: newEmail }, {
        // headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setMessage(response.data.message);
      setEmail(newEmail);
      setAuth((prevAuth) => ({ ...prevAuth, email: newEmail }));
    } catch (error) {
      setError(error.response.data.error);
    }
  };


  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Profile</h2>
        <p>Email: {email}</p>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        {message && <p className="mb-4 text-green-500">{message}</p>}
        
        <form onSubmit={handleEmailChange} className="mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-email">
              New Email
            </label>
            <input
              type="email"
              id="new-email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Change Email
          </button>
        </form>


      </div>
    </div>
  );
};

export default Profile;
