import React from "react";
import Sidebar from '../components/Sidebar';
 
const Chatbot_rag = () => {
    return (
        <div className="flex">
            <Sidebar />      
            <div className="p-4">
                <div>
                    <h1>
                        Chatbot with RAG ability
                    </h1>
                    <iframe
                        src="https://jamesthong-chatbot-rag.hf.space"
                        frameborder="0"
                        width="850"
                        height="800"
                        title="Chatbot_rag"
                    ></iframe>
                </div>
            </div>
        </div>
    )
};
 
export default Chatbot_rag;