import React from 'react';
import { motion } from 'framer-motion';

const LLM = () => {
  return (
    <section id="features" className="py-20">
      <div className="container mx-auto text-center">
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <motion.div 
            className="p-4 rounded-md"
            initial={{ opacity: 0, y: 50 }}
            // animate={{ opacity: 1, y: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            
          >
            <img className="rounded-full object-cover" src={require("../images/llama_math.png")} alt="llm" />
          </motion.div>
          <motion.div 
            className="p-4 rounded-md"
            initial={{ opacity: 0, x: 50 }}
            // animate={{ opacity: 1, y: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 2, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <h3 className="text-2xl font-semibold text-gray-800">Large Language Model (LLM)</h3>
            <p className="text-gray-600 mt-2">Chatbots, machine translation, content generation, and more.</p>
          </motion.div>
         
        </div>
      </div>
    </section>
  );
};

export default LLM;
