
import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
// import Contact from '../components/Contacts';
import LLM from '../components/LLM';
import Multimodal from '../components/Multimodal';
import Agents from '../components/Agents.js';
import FloatingActionButton from '../components/Fab.js';

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <LLM />
      <Multimodal />
      <Agents />
      <About />
      {/* <Contact /> */}
      <FloatingActionButton />
    </div>

  );
};

export default App;
