import React, {  useState, useContext }from 'react';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
// import axios from './axiosConfig';
import { AuthContext } from '../components/AuthContext';
import { ChevronDown } from "lucide-react";

const Header = () => {
  const { auth, logout } = useContext(AuthContext);
  const [expanded, setExpanded] = useState({
    account: false,

  });

  const toggleExpand = (section) => {
      setExpanded((prev) => ({
      ...prev,
      [section]: !prev[section],
      }));
  };

  const account = [
    { link: "/profile", label: 'Profile' },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await logout();
      console.log(response);
    } catch (err) {
      console.log('Logout failed.', err);
    }

  };


  // console.log(auth.email)

  return (
    <motion.header 
      className="bg-white shadow-md"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="text-2xl font-bold text-gray-800 ">Instann</div>
        <nav className="space-x-4 ">
          <a href="#features" className="text-gray-600 hover:text-gray-800">Features</a>
          <a href="#about" className="text-gray-600 hover:text-gray-800">About</a>
          <a href="#contact" className="text-gray-600 hover:text-gray-800">Contact</a>
        </nav>
        <div className="space-x-4">
          {auth ? 
           <div className="inline-block">     
              <button
                onClick={() => toggleExpand('account')}
                className="text-gray-600 hover:text-gray-800"
                
                >
                 {auth.email}
       
                <ChevronDown 
                  className={`w-5 h-5 ml-2 inline-block transition-transform duration-200 
                  ${expanded.account ? 'transform rotate-180' : ''}`} />
              </button>         
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: expanded.account ? 1 : 0, y: expanded.account ? 0 : -10 }}
                transition={{ duration: 0.5 }}
                className="absolute z-10 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style={{ display: expanded.account ? 'block' : 'none' }}
                >
                  {expanded.account && (
                  <ul className="absolute ">
                    {account.map((link, index) => (
                      <li key={index} className="mt-1">
                          <Link to={link.link} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{link.label}</Link>
                      </li>))}
                    <button 
                      onClick={handleSubmit} 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Logout</button>
                  </ul>
                  )} 
              </motion.div>
            </div> :
            <Link to="/login" className="text-gray-600 hover:text-gray-800">
              Login 
            </Link>}
            <Link to="/signup" className="text-gray-600 hover:text-gray-800">
              Sign Up
            </Link>
        </div>
        
      </div>
    </motion.header>
  );
};

export default Header;
