import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle,CircleX } from "lucide-react";

const ChatbotPopup = ({ onClose }) => {

  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I assist you today?' },
  ]);

  const [input, setInput] = useState('');

  const messagesEndRef = useRef(null);

  const handleSend = () => {
    if (input.trim() === '') return;

    setMessages([...messages, { sender: 'user', text: input }]);
    setInput('');

    // Simulate bot response
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        { sender: 'bot', text: "I'm here to help!" },
        ]);
      }, 500);
    };
  
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);



  return (
    <div className="fixed bottom-20 right-4 bg-white p-4 rounded-lg shadow-lg w-80">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold">AI Assistant (Under construction)</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
          <CircleX size={20} />
        </button>
      </div>
      <div className="h-64 overflow-y-scroll overscroll-auto">    
        {messages.map((message, index) => (
            <div key={index} className={`mb-2 flex ${message.sender === 'bot' ? 'justify-start' : 'justify-end'}`}>
              <div className={`max-w-xs p-2 rounded-lg text-white ${message.sender === 'bot' ? 'bg-blue-500' : 'bg-green-500'}`}>
                {message.text}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
      </div>
      <div className="mt-4">    
        <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
            placeholder="Type a message..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
      </div>
    </div>
  );
};

const FloatingActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {isOpen && <ChatbotPopup onClose={() => setIsOpen(false)} />}
      <div className="fixed bottom-4 right-4">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded-full shadow-lg"
        >
          <MessageCircle size={24} />
        </button>
      </div>
    </div>
  );
};



export default FloatingActionButton;
