import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import { ChevronsDown, ChevronsRight } from "lucide-react";

function Sidebar() {

    const [expanded, setExpanded] = useState({
        llm: false,
        multimodal: false,
        agents: false,
    });

    const toggleExpand = (section) => {
        setExpanded((prev) => ({
        ...prev,
        [section]: !prev[section],
        }));
    };

    const llm = [
      { link: "/chatbot/memory", label: 'Chatbot memory' },
      { link: "/chatbot/rag", label: 'Chatbot RAG' },
    ]

    const multimodal = [
        { link: "/chatbot/memory", label: 'Chatbot memory' },
        { link: "/chatbot/rag", label: 'Chatbot RAG' },
    ]

    const agents = [
        { link: "/chatbot/memory", label: 'Chatbot memory' },
        { link: "/chatbot/rag", label: 'Chatbot RAG' },
    ]

    return (

        <div className="container mx-auto p-4">
            <Link to="/" className="block p-2 hover:bg-gray-600 rounded mb-4">Home</Link>
            <div className="mb-4">
                <button
                onClick={() => toggleExpand('llm')}
                className="w-full text-left p-2 bg-blue-500 text-white rounded-md flex items-center justify-between"
                >
                LLM
                {expanded.llm ?  <ChevronsRight className="inline-block ml-2"/> : <ChevronsDown className="inline-block ml-2"  />}
                </button>
                {expanded.llm && (
                <ul className="mt-2 pl-4 ">
                    {llm.map((link, index) => (
                        <li key={index} className="mt-1">
                            <Link to={link.link} className="block p-2 hover:bg-gray-600 rounded">{link.label}</Link>
                        </li>))}
                </ul>
                )}
            </div>
            <div className="mb-4">
                <button
                onClick={() => toggleExpand('multimodal')}
                className="w-full text-left p-2 bg-blue-500 text-white rounded-md flex items-center justify-between"
                >
                Multimodal
                {expanded.multimodal ?  <ChevronsRight className="inline-block ml-2"/> : <ChevronsDown className="inline-block ml-2"  />}
                </button>
                {expanded.multimodal && (
                <ul className="mt-2 pl-4 ">
                {multimodal.map((link, index) => (
                    <li key={index} className="mt-1">
                        <Link to={link.link} className="block p-2 hover:bg-gray-600 rounded">{link.label}</Link>
                    </li>))}
                </ul>
                )}
            </div>
            <div className="mb-4">
                <button
                onClick={() => toggleExpand('agents')}
                className="w-full text-left p-2 bg-blue-500 text-white rounded-md flex items-center justify-between"
                >
                AI Agents
                {expanded.agents ?  <ChevronsRight className="inline-block ml-2"/> : <ChevronsDown className="inline-block ml-2"  />}
                </button>
                {expanded.agents && (
                <ul className="mt-2 pl-4 ">
                {agents.map((link, index) => (
                    <li key={index} className="mt-1">
                        <Link to={link.link} className="block p-2 hover:bg-gray-600 rounded">{link.label}</Link>
                    </li>))}
                </ul>
                )}
            </div>
        </div>
        );
    };


export default Sidebar;