import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <motion.section 
      className="bg-gray-100 py-20 h-screen overflow-auto flex"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto text-center content-center">
        <h1 className="text-5xl font-bold text-gray-800">Welcome to Instann</h1>
        <p className="text-gray-600 mt-4">Your one-stop solution for generative AI</p>
        <motion.button 
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          
        >          
          <Link to="/chatbot/memory">Get Started</Link>
        </motion.button>
      </div>
    </motion.section>
  );
};

export default Hero;
