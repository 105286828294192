import React from "react";
import Sidebar from '../components/Sidebar';

const Chatbot_memory = () => {
    return (
        <div className="flex">
            <Sidebar />      
            <div className="p-4">
                <div>
                    <h1>
                        Chatbot with memory ability
                    </h1>
                    <iframe
                        src="https://jamesthong-chatbot-llama-3-8b-instruct.hf.space"
                        frameborder="0"
                        width="850"
                        height="800"
                        title="Chatbot Memory"
                    ></iframe>
                </div>
            </div>    
        </div>
    );
};
 
export default Chatbot_memory;